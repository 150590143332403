/**
 * Element.imagesLoaded() polyfill
 */

import {imagesLoaded} from './imagesLoaded';

if (!Element.prototype.imagesLoaded) {
  Element.prototype.imagesLoaded = function(cb) {
    return imagesLoaded(this, cb);
  };
}
