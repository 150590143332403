export default function loadRecaptchaAPI(cb, lang) {
  if(!document.querySelector('script[id=recaptcha-api]')) {

    // Listen ready
    window.onLoadRecaptchaCallback = function(){
      console.log('onLoadRecaptchaCallback');
      cb && cb();
    };

    // Call the youtube api
    let tag = document.createElement('script');
    tag.id = 'recaptcha-api';
    tag.src = 'https://www.google.com/recaptcha/api.js?onload=onLoadRecaptchaCallback&render=explicit&hl=' + (lang || 'tr');
    let firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
  } else {
    cb && cb();
  }
}
