import $ from 'jquery';
import createElement from 'Utils/createElement';

const ua = window.navigator.userAgent;

export function isMobileWidth(){
  return $('body').outerWidth() < 768;
}

export function hasObjectFit(){
  // objectFit returns false on Chrome but objectfit returns true
  return 'objectFit' in document.documentElement.style === true;
}

export function hasCSSFilters(){
  let el = createElement('a');
  el.style.cssText = '-webkit-filter:blur(2px); filter:blur(2px);';
  // https://github.com/Modernizr/Modernizr/issues/615
  // documentMode is needed for false positives in oldIE, please see issue above
  return !!el.style.length && ((document.documentMode === undefined || document.documentMode > 9));
}

export function isIE(){
  return /(MSIE|Trident)/.test(ua);
}

export const featureTests = {
  "object-fit": hasObjectFit,
  "css-filters": hasCSSFilters,
};

export const browserTests = {
  "ie": isIE
};

export default function detectFeatures(){
  const $html = $('html');

  $html.removeClass('no-js').addClass('js');

  for (let feature in featureTests) {
    $html.addClass( (featureTests[feature]() ? 'has-' : 'no-') + feature );
  }

  for (let feature in browserTests) {
    if(browserTests[feature]()) {
      $html.addClass( 'is-' + feature );
    }
  }
}
