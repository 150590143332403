import $ from 'jquery';

let $doc = $(document);

export function onFormFocus(e) {
  let $this = $(this);

  if ($this.data('form-focused-helper') === false) {
    return;
  }

  $this.closest('.c-form__group').toggleClass('is-focused', e.type === 'focusin');
}

export function onFormInput() {
  let $this = $(this);
  if ($this.data('form-filled-helper') === false) {
    return;
  }
  $this.closest('.c-form__group').toggleClass('is-filled', $this.val().length > 0);
}

export function focusTracking() {
  $doc.on('focusin focusout', '.c-form__input', onFormFocus);
  $doc.on('input', '.c-form__input', onFormInput);
  $doc.on('change', '.c-form__input--select', onFormInput);
}

export function enhancedSelect() {

  let onToggleClick = function (e) {
    let $this = $(this);
    if($this.is('.c-form__select-rendered')) {
      $this.closest('.c-form__group').toggleClass('is-opened');
    }
  };

  let onCloseOverlay = function (e) {
    if ($(e.target).closest('.c-form__group--select').length === 0) {
      $('.c-form__group--select').removeClass('is-opened');
    }
  };

  let replaceFormat = function (format, state) {
    if (format.indexOf('{image}') !== -1) {
      format = format.replace('{image}', '<img class="c-form__option-image" src="' + state.element.getAttribute('data-image') + '" alt="' + state.id + '">');
    }
    if (format.indexOf('{flag}') !== -1) {
      format = format.replace('{flag}', '<span class="c-form__option-flag flag flag-' + state.element.getAttribute('data-flag') + '"></span>');
    }
    if (format.indexOf('{text}') !== -1) {
      format = format.replace('{text}', '<span class="c-form__option-text">' + state.text + '</span>');
    }
    if (format.indexOf('{id}') !== -1) {
      format = format.replace('{id}', '<span class="c-form__option-id">' + state.id + '</span>');
    }
    return format;
  };

  let formatState = function (state, $select, type) {
    if (state.disabled) {
      return state.text;
    }

    if ($select.data(type + '-format')) {
      return replaceFormat($select.data(type + '-format'), state);
    }

    return state.text;
  };

  let onSelect = function (e) {
    let $option = $(e.target);
    if($option.is('.c-form__select-item')) {
      let $group = $option.closest('.c-form__group');
      let $rendered = $group.find('.c-form__select-rendered');
      let $select = $option.closest('.c-form__select-options').nextAll('.c-form__input--select').first();
      let id = $option.data('id');
      let text = $option.text();
      let selection = formatState({element: $option[0], id: id, text: text}, $select, 'selection');
      $select.val(id);
      $rendered.html(selection);
      $option.addClass('is-selected').siblings().removeClass('is-selected');

      // console.log('id', id);
      $group.toggleClass('is-filled', !!id);
      $group.removeClass('is-opened', !!id);
    }
  };

  $('[data-ride="select"]').each(function () {
    let $select = $(this);
    let $field = $select.closest('.c-form__field');
    let $group = $select.closest('.c-form__group');
    let options = [];
    let optionsHtml = [];
    let renderedText = '';
    let $firstOption;

    $group.addClass('c-form__group--select');
    optionsHtml.push('<ul class="c-form__select-list">');
    $select.children().each(function () {
      let $option = $(this);
      if(!$firstOption) {
        $firstOption = $option;
      }
      let id = $option.val();
      let flag = $option.data('flag') || false;
      let text = $option.text();
      let selection = formatState({element: $option[0], id: id, text: text}, $select, 'selection');
      let result = formatState({element: $option[0], id: id, text: text}, $select, 'result');
      let isSelected = $option.is('[selected]');
      options.push({id: id, text: text});
      if (isSelected) {
        renderedText = selection;
        $group.addClass('is-filled');
      }

      // if (id) {
        optionsHtml.push('<li class="c-form__select-item' + (isSelected ? ' is-selected' : '') + '" data-id="' + id + '"'+ (flag ? ' data-flag="'+flag+'"' : '') +'>' + result + '</li>');
      // }
    });
    optionsHtml.push('</ul>');
    let placeholder = renderedText || formatState({element: $firstOption[0], id: $firstOption.val(), text: $firstOption.text()}, $select, 'selection');
    $field.prepend('<div class="c-form__select-rendered" tabindex="-1">' + placeholder + '</div><div class="c-form__select-options" tabindex="-1">' + optionsHtml.join('') + '</div>');
  });

  $doc.on('click', '.c-form__select-rendered', onToggleClick);

  $doc.on('click', '.c-form__select-item', onSelect);

  $doc.on('click', onCloseOverlay);
}

export default function formHelpers() {
  focusTracking();
  enhancedSelect();
}
