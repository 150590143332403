/**
 * @param callbackObj Object An object with callbacks in .start, .progress, and .done
 * @param duration Integer Total duration in seconds
 */

export default function animate(callbackObj, duration) {
  let requestAnimationFrame = window.requestAnimationFrame || window.webkitRequestAnimationFrame || window.mozRequestAnimationFrame || window.oRequestAnimationFrame || window.msRequestAnimationFrame;
  let startTime = 0, percentage = 0, animationTime = 0;

  duration = duration*1000 || 1000;

  let animation = function(timestamp) {

    if (startTime === 0) {
      startTime = timestamp;
    } else {
      animationTime = timestamp - startTime;
    }

    if (typeof callbackObj.start === 'function' && startTime === timestamp) {
      callbackObj.start();

      requestAnimationFrame(animation);
    } else if (animationTime < duration) {
      if (typeof callbackObj.progress === 'function') {
        percentage = animationTime / duration;
        callbackObj.progress(percentage);
      }

      requestAnimationFrame(animation);
    } else if (typeof callbackObj.done === 'function'){
      callbackObj.done();
    }
  };

  return requestAnimationFrame(animation);
}
