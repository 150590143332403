/*
 * @title Common
 * @description Common scripts
 */

import 'picturefill';
// import 'intersection-observer';
// import $ from 'jquery';
import detectFeatures from 'Utils/detectFeatures';
import matchHeight from 'Utils/matchHeight';
import formHelpers from 'Utils/formHelpers';
import recaptcha from 'Utils/recaptcha';
import scrollClasses from 'Utils/scrollClasses';
import carousel from 'Modules/carousel/carousel';
import lightbox from 'Modules/lightbox/lightbox';
import responsiveVideo from 'Modules/responsive-video/responsive-video';
import toggleClass from 'Components/toggle-class';
import LazyLoad from "vanilla-lazyload";
import SmoothScroll from 'smooth-scroll';
import scrolSpy from 'Utils/scrollSpy';

import PubSub from 'pubsub-js';
const publish = PubSub.publish;
const subscribe = PubSub.subscribe;

let alreadyInit = false;
const init = function(){
  if(alreadyInit) {
    return;
  }

  alreadyInit = true;

  detectFeatures();
  matchHeight();
  formHelpers();
  recaptcha();
  scrollClasses();
  toggleClass();
  carousel();
  lightbox();
  responsiveVideo();
  new SmoothScroll('a[href*="#"]:not([href="#"])');
  scrolSpy('[data-spy]');

  let lazyLoad = new LazyLoad({
    elements_selector: ".lazy",
    callback_loaded: function(el){
      window.picturefill({
        reevaluate: true, // Awesome :D
        elements: [el]
      });
    },
    callback_finish: function(){
      window.picturefill({
        reevaluate: true
      });
      publish('/lazy/finish');
    }
  });

  publish('all-scripts-are-loaded');

  let onScrolled = function(){
    lazyLoad.loadAll();
  };
  subscribe('/scroll-classes/scrolled', onScrolled);
};

document.addEventListener('DOMContentLoaded', init);
window.addEventListener('load', init);
