export default function addYoutubeVideo(el, videoId, onReady, onComplete, playerTargetHolder) {
  // window.youtubeIframeId = (window.youtubeIframeId || 0) + 1;
  // let iframeId = 'yt-iframe-' + window.youtubeIframeId;
  // let iframe = document.createElement( "iframe" );
  // iframe.setAttribute( "id", iframeId);
  // iframe.setAttribute( "frameborder", "0" );
  // iframe.setAttribute( "allowfullscreen", "" );
  // iframe.setAttribute( "src", "https://www.youtube.com/embed/"+ videoId +"?rel=0&showinfo=0&autoplay=1&enbalejsapi=1" );
  // iframe.setAttribute( "sandbox", "allow-same-origin allow-scripts" );
  //
  // el.innerHTML = "";
  // el.appendChild( iframe );
  //
  // let player = new YT.Player(iframeId);

  let player = new YT.Player(el, {
    videoId: videoId,
    host: 'https://www.youtube.com',
    playerVars: { autoplay: 1, modestbranding: 1, rel: 0, showinfo: 0, controls: 1 },
    events: {
      'onReady': onPlayerReady,
      'onStateChange': onPlayerStateChange
    }
  });

  // 4. The API will call this function when the video player is ready.
  function onPlayerReady(event) {
    event.target.playVideo();
    onReady && onReady(player, event);

    if(playerTargetHolder) {
      window[playerTargetHolder][videoId] = event.target;
    }
  }

  // 5. The API calls this function when the player's state changes.
  //    The function indicates that when playing a video (state=1),
  //    the player should play for six seconds and then stop.
  let done = false;
  function onPlayerStateChange(event) {
    if (event.data === 0 && !done) {
      done = true;
      if(onComplete) {
        setTimeout(function () {
          // player.stopVideo();
          onComplete(event);
        }, 500);
      }
    }
  }
}
