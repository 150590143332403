import $ from 'jquery';
import VanillaScrollspy from 'Utils/vanillaJsScrollSpyModified';
import PubSub from 'pubsub-js';
const subscribe = PubSub.subscribe;
const $html = $('html');

export default function scrollSpy(selector) {
  $(selector).each(function () {
    const ss = new VanillaScrollspy(this);
    ss.init();
  });

  const onSpyActive = function (message, response) {
    window.preventScrollClasses = true;
    !response.$menu.hasClass('is-affix') && response.$menu.addClass('is-affix');
  };

  const onSpyPassive = function (message, response) {
    window.preventScrollClasses = false;
    response.$menu.hasClass('is-affix') && response.$menu.removeClass('is-affix');
  };

  subscribe('scroll-spy-active', onSpyActive);
  subscribe('scroll-spy-passive', onSpyPassive);
}
