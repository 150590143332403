'use strict';

import $ from 'jquery';
import PubSub from 'pubsub-js';
// const subscribe = PubSub.subscribe;
const publish = PubSub.publish;

// Object.defineProperty(exports, "__esModule", {
//   value: true
// });

let $doc = $(document);

let _createClass = function () { function defineProperties(target, props) { for (let i = 0; i < props.length; i++) { let descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

let fncAnimation = function fncAnimation(callback) {
  window.setTimeout(callback, 1000 / 60);
  return callback;
};

window.requestAnimFrame = function () {
  return window.requestAnimationFrame || window.webkitRequestAnimationFrame || window.mozRequestAnimationFrame || fncAnimation;
}();

let VanillaScrollspy = function () {
  function VanillaScrollspy(menu) {
    let speed = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2000;
    let easing = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'easeOutSine';

    _classCallCheck(this, VanillaScrollspy);

    this.menu = menu;
    this.speed = speed;
    this.easing = easing;
  }

  _createClass(VanillaScrollspy, [{
    key: 'scrollToY',
    value: function scrollToY() {
      let _this = this;

      let targetY = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;

      let scrollTargetY = targetY;
      let scrollY = window.scrollY || document.documentElement.scrollTop;
      let currentTime = 0;
      let time = Math.max(0.1, Math.min(Math.abs(scrollY - scrollTargetY) / this.speed, 0.8));

      let easingEquations = {
        easeOutSine: function easeOutSine(pos) {
          return Math.sin(pos * (Math.PI / 2));
        },
        easeInOutSine: function easeInOutSine(pos) {
          return -0.5 * (Math.cos(Math.PI * pos) - 1);
        },
        easeInOutQuint: function easeInOutQuint(pos) {
          /* eslint-disable-next-line */
          if ((pos /= 0.5) < 1) {
            return 0.5 * Math.pow(pos, 5);
          }
          return 0.5 * (Math.pow(pos - 2, 5) + 2);
        }
      };

      let tick = function tick() {
        currentTime += 1 / 60;
        let p = currentTime / time;
        let t = easingEquations[_this.easing](p);

        if (p < 1) {
          window.requestAnimFrame(tick);
          window.scrollTo(0, scrollY + (scrollTargetY - scrollY) * t);
        } else {
          window.scrollTo(0, scrollTargetY);
        }
      };

      tick();
    }
  }, {
    key: 'menuControl',
    value: function menuControl() {
      let i = void 0;
      let currLink = void 0;
      let $currLink = void 0;
      let $parent = void 0;
      let refElement = void 0;
      let $menu = $(this.menu);
      let $links = $menu.find('a[href^="#"]');
      let scrollPos = window.scrollY || document.documentElement.scrollTop;

      $links.each(function(){
        currLink = this;
        $currLink = $(this);
        $parent = $currLink.parent('li') ? $currLink.parent('li') : $currLink;
        refElement = $doc.find($currLink.attr('href'))[0];
        // refElement = document.querySelector(currLink.getAttribute('href'));

        if (refElement.offsetTop <= scrollPos && refElement.offsetTop + refElement.clientHeight > scrollPos) {
          $parent.addClass('is-active');
          // currLink.classList.add('is-active');
        } else {
          // currLink.classList.remove('is-active');
          $parent.removeClass('is-active');
        }
      });

      if($menu.find('.is-active').length > 0) {
        publish('scroll-spy-active', { $menu: $menu, scrollPos: scrollPos });
      } else {
        publish('scroll-spy-passive', { $menu: $menu, scrollPos: scrollPos });
      }

      // for (i = 0; i < links.length; i += 1) {
      //   currLink = links[i];
      //   $currLink = $(currLink);
      //   $parent = $currLink.parent('li') ? $currLink.parent('li') : $currLink;
      //   refElement = document.querySelector(currLink.getAttribute('href'));
      //
      //   if (refElement.offsetTop <= scrollPos && refElement.offsetTop + refElement.clientHeight > scrollPos) {
      //     $parent.addClass('is-active');
      //     // currLink.classList.add('is-active');
      //   } else {
      //     // currLink.classList.remove('is-active');
      //     $parent.removeClass('is-active');
      //   }
      // }

    }
  }, {
    key: 'animated',
    value: function animated() {
      let self = this;
      function control(e) {
        e.preventDefault();
        let target = document.querySelector(this.hash);
        self.scrollToY(target.offsetTop);
      }

      let i = void 0;
      let link = void 0;
      let links = this.menu.querySelectorAll('a[href^="#"]');

      for (i = 0; i < links.length; i += 1) {
        link = links[i];
        link.addEventListener('click', control);
      }
    }
  }, {
    key: 'init',
    value: function init() {
      let _this2 = this;

      this.animated();
      document.addEventListener('scroll', function () {
        _this2.menuControl();
      });
    }
  }]);

  return VanillaScrollspy;
}();

export default VanillaScrollspy;
