/**
 * Images loaded
 */

import $ from 'jquery';

export function wait(url, cb){
  let img = new Image();
  img.onload = function() {
    cb(url, 1);
  };
  img.onerror = function() {
    cb(url, 0);
  };
  img.src = url;
}


export function imagesLoaded(el, cb){
  let images = el.querySelectorAll('img');
  if(images.length > 0) {
    $images = $(images);
    let count = 0;
    $images.each(function(idx, el){
      wait(el.getAttribute('src'), function(){
        if(++count === images.length) {
          cb();
        }
      });
    }, el);
  } else {
    cb();
  }
}
