export default function requestFullScreen(el, delay){
  let requestFullScreen = el.requestFullScreen || el.mozRequestFullScreen || el.webkitRequestFullScreen;
  if (requestFullScreen) {
    if(delay) {
      setTimeout(function () {
        requestFullScreen.bind(el)();
      }, 1000);
    } else {
      requestFullScreen.bind(el)();
    }
  }
}
