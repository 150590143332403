import $ from 'jquery';

const visible = function (elem) {
  elem = $(elem)
  return !!(elem.width() || elem.height()) && elem.css("display") !== "none"
};

let filters = {
  visible: function () {
    if (visible(this)) return this
  },
  hidden: function () {
    if (!visible(this)) return this
  },
  selected: function () {
    if (this.selected) return this
  },
  checked: function () {
    if (this.checked) return this
  },
  parent: function () {
    return this.parentNode
  },
  first: function (idx) {
    if (idx === 0) return this
  },
  last: function (idx, nodes) {
    if (idx === nodes.length - 1) return this
  },
  eq: function (idx, _, value) {
    if (idx === value) return this
  },
  contains: function (idx, _, text) {
    if ($(this).text().indexOf(text) > -1) return this
  },
  has: function (idx, _, sel) {
    if ($(this).find(sel).length) return this
  }
};

let filterRe = new RegExp('(.*):(\\w+)(?:\\(([^)]+)\\))?$\\s*'),
  childRe = /^\s*>/,
  classTag = 'Zepto' + (+new Date())

function process(sel, fn) {
  // quote the hash in `a[href^=#]` expression
  sel = sel.replace(/=#\]/g, '="#"]')
  let filter, arg, match = filterRe.exec(sel)
  if (match && match[2] in filters) {
    filter = filters[match[2]], arg = match[3]
    sel = match[1]
    if (arg) {
      let num = Number(arg)
      if (isNaN(num)) arg = arg.replace(/^["']|["']$/g, '')
      else arg = num
    }
  }
  return fn(sel, filter, arg)
}

export function matches(selector, node) {
  // console.log(selector, $(node).attr('class'), filters.visible.call(node) == node);
  return process(selector, function (sel, filter, arg) {
    return (!sel || $.matches(node, sel)) && ( !filter || filter.call(node) === node )
    // return !filter || filter.call(node, null, arg) === node
  })
}

export default function filterSelectorAdvanced(selector) {
  // return qsa(this, selector);

  let results = [];
  this.each(function () {
    if (matches(selector, this)) {
      console.log('matches', selector, this);
      results.push(this);
    }
  });

  if(results.length > 0) {
    let $results;
    $.each(results, function(idx, result){
      if(idx === 0) {
        $results = $(result);
      } else {
        $results = $results.add(result);
      }
    });
    return $results;
  }

  return $();
}
