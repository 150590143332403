/**
 * createElement is a convenience wrapper around document.createElement. Since we
 * use createElement all over the place, this allows for (slightly) smaller code
 * as well as abstracting away issues with creating elements in contexts other than
 * HTML documents (e.g. SVG documents).
 *
 * @access private
 * @function createElement
 * @returns {HTMLElement|SVGElement} An HTML or SVG element
 */
export default function createElement() {
  if (typeof document.createElement !== 'function') {
    // This is the case in IE7, where the type of createElement is "object".
    // For this reason, we cannot call apply() as Object is not a Function.
    return document.createElement(arguments[0]);
  } else if (arguments[0] === 'svg') {
    return document.createElementNS.call(document, 'http://www.w3.org/2000/svg', arguments[0]);
  } else {
    return document.createElement.apply(document, arguments);
  }
}
