// http://youmightnotneedjquery.com/ IE9+ Fade in

export default function animationIterator(cb, duration) {
  if(!cb) {
    return;
  }

  duration = duration || 400;

  let state = 0;

  let last = +new Date();
  let tick = function () {
    state = +state + (new Date() - last) / duration;

    cb(state);

    last = +new Date();
    if (+state < 1) {
      (window.requestAnimationFrame && requestAnimationFrame(tick)) || setTimeout(tick, 16);
    }
  };

  tick();
}
