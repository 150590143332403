/**
 * Toggle class on click
 */

import $ from 'jquery';

export default function toggleClass() {

  document.addEventListener('click', function (e) {

    let $target;
    let $this = $(e.target);

    if ($this.is('[data-toggle-class]')) {
      e.preventDefault();

      // Get the content
      $target = $( $this.data('target') );
      if (!$target) return;

      // Toggle the content
      $target.toggleClass( $this.data('toggle-class') );
    }

    $('[data-toggle-class][data-dismissable]').each(function(){
      let $that = $(this);
      if(!$that.is(e.target)) {
        $target = $($that.data('target'));
        if (!$target) return;
        $target.removeClass($that.data('toggle-class'));
      }
    });

  }, false);

}
