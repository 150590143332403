/**
 * Carousel
 * https://flickity.metafizzy.co/
 */

import Flickity from 'flickity';
// import Flickity from 'flickity-as-nav-for/as-nav-for'; // I have to do it myself because it did not work both area
// import 'flickity/dist/flickity.min.css';

import $ from 'jquery';
import {debounce} from 'lodash';
import getDeviceState from 'Utils/getDeviceState';
import PubSub from 'pubsub-js';
import animate from 'Utils/animate';

const subscribe = PubSub.subscribe;
const publish = PubSub.publish;

let reInitAllCarousels = function () {
  $('[data-ride="carousel"]').each(function () {
    let carousel = this;
    let $carousel = $(this);
    $carousel.find('.c-carousel__page-numbers').remove();
    let flkty = Flickity.data(carousel);
    flkty.destroy();
    setTimeout(function () {
      carouselInit(carousel);
    }, 0);
  });
};

let rePositionCarousel = function (el) {
  let flkty = Flickity.data(el);
  if (flkty) {
    flkty.resize();
  }
};

let rePositionAllCarousels = function (el) {
  $('[data-ride="carousel"]').each(function () {
    rePositionCarousel(this);
  });
};

// let rePositionAllCarousels = function () {
//   $('[data-ride="carousel"]').each(function () {
//     rePositionCarousel(this);
//   });
// };

let onDeviceStateChange = function (state, data) {
  // console.log( '/device-state/change', state, data );
  reInitAllCarousels();
};

// let onResponsiveImagesLoaded = function(){
//   console.log('onResponsiveImagesLoaded');
//   rePositionAllCarousels();
// };

subscribe('/device-state/change', onDeviceStateChange);
// subscribe('/responsive-images/loaded', onResponsiveImagesLoaded);
// subscribe('all-scripts-are-loaded', rePositionAllCarousels);

let onFinishLazy = function(){
  rePositionAllCarousels();
};
subscribe('/lazy/finish', onFinishLazy);

let lastDeviceState = getDeviceState();
window.addEventListener('resize', debounce(function () {
  let state = getDeviceState();
  if (state != lastDeviceState) {
    // Save the new state as current
    lastDeviceState = state;

    // Announce the state change via pub/sub
    publish('/device-state/change', [state]);
  }
}, 20));

const hasOnePage = function ($carousel) {
  let $selected = $carousel.find('.flickity-slider').children();
  if ($selected.length > 0) {
    // console.log($selected, $carousel.find('.flickity-viewport').outerWidth(), $selected.length * $selected.first().outerWidth());
    return $carousel.find('.flickity-viewport').outerWidth() === $selected.length * $selected.first().outerWidth();
  }

  $selected = $carousel.children();
  return $carousel.outerWidth() === $selected.length * $selected.first().outerWidth();
};

const getOptionByBreakpoint = function (options) {
  let outerWidth = $('body').outerWidth();
  let result = false;

  $.each(options, function (idx, option) {
    if (option.breakpoint <= outerWidth) {
      result = option.options;
    }
  });

  if (!result) {
    return false;
  }

  return $.extend({}, result);
};

const getOptionsByElement = function ($carousel) {
  let options = $carousel.data('options') || {};

  // if(hasOnePage($carousel)) {
  //   options.pageDots = false;
  // }

  let responsiveOptions = false;
  if (options.responsive) {
    responsiveOptions = getOptionByBreakpoint(options.responsive);
    if (responsiveOptions) {
      options = $.extend(options, responsiveOptions);
    }
    delete options.responsive;
  }

  return options;
};

const pauseVideos = function ($carousel) {
  $carousel.find('video').each(function () {
    this.pause();
  });
};

const playVideos = function ($carousel) {
  $carousel.find('video').each(function () {
    this.play();
  });
};

const navigateAsNavFor = function ($carousel, settings, index) {
  if ($carousel.data('disable-navigate-as-nav-for')) {
    return;
  }

  let $target;
  if (settings.asNavFor) {
    $target = $(settings.asNavFor);
  } else {
    let id = $carousel.attr('id');
    $target = $('.c-carousel[data-options*="asNavFor"][data-options*="' + id + '"]');
  }

  if ($target) {
    let flkty = Flickity.data($target[0]);
    if (flkty) {
      $target.data('disable-navigate-as-nav-for', true);
      flkty.select(index);
      setTimeout(function () {
        $target.data('disable-navigate-as-nav-for', false);
      }, 16);
    }
  }
};

const addTitlesToDots = function ($carousel, settings) {
  if (!settings.dotTitlesFrom) {
    return;
  }

  let $titles = $carousel.find(settings.dotTitlesFrom);
  let $dots = $carousel.find('.dot');
  let method = settings.dotTitlesMethod === 'text' ? 'text' : 'html';

  $titles.each(function (index) {
    $dots.eq(index).html($(this)[method]());
  });
};

const wrapDots = function ($carousel, settings) {
  if (!settings.wrapDots) {
    return;
  }

  $carousel.find('.flickity-page-dots').attr('class', 'flickity-page-dots-inner').wrap('<div class="flickity-page-dots"></div>');
};

const getFlickityOfElement = function (el) {
  return Flickity.data(el);
};

const pad = function (n, width, z) {
  z = z || '0';
  n = n + '';
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
};

const addPageNumbers = function ($carousel, settings) {
  if (!settings.pageNumbers || $carousel.find('.c-carousel__page-numbers').length > 0) {
    return;
  }

  let count = getFlickityOfElement($carousel[0]).cells.length;
  let progress = (100 * (1 / count).toFixed(4));

  $carousel.append('<span class="c-carousel__page-numbers"><span class="c-carousel__page-current" aria-label="Current Page">' + pad(1, settings.pageNumbersPad || 0) + '</span><span class="c-carousel__page-total" aria-label="Total Pages">' + pad(count, settings.pageNumbersPad || 0) + '</span><span class="c-carousel__page-progress"><span class="c-carousel__page-progress-inner" style="width: ' + progress + '%;"></span></span>');
};

const updatePageNumbers = function ($carousel, settings, index) {
  if (!settings.pageNumbers) {
    return;
  }

  let current = index + 1;
  let count = getFlickityOfElement($carousel[0]).cells.length;
  let progress = (100 * (current / count).toFixed(4));

  $carousel.find('.c-carousel__page-current').text(pad(index + 1, settings.pageNumbersPad || 0));
  $carousel.find('.c-carousel__page-progress-inner').css('width', progress + '%');
};

const easeInOutQuad = function (t) {
  return t < .5 ? 2 * t * t : -1 + (4 - 2 * t) * t
};

const getDirection = function ($carousel, settings, index) {
  let flkty = getFlickityOfElement($carousel[0]);
  let previousIndex = $carousel.data('previousIndex');
  let delta = index - previousIndex;
  let lastIndex = flkty.slides.length - 1;
  let direction;
  if ( previousIndex === lastIndex && index === 0 ) {
    // console.log('wrapped forward');
    direction = 'next';
  } else if ( previousIndex === 0 && index === lastIndex ) {
    // console.log('wrapped back')
    direction = 'previous';
  } else if ( delta > 0 ) {
    // console.log( 'moved forward', delta )
    direction = 'next';
  } else {
    // console.log( 'moved back', delta )
    direction = 'previous';
  }

  return direction;
  // console.log(direction);
};

const updateDotsScrollPositions = function ($carousel, settings, index, direction) {
  if (!settings.updateDotsScrollPositions) {
    return;
  }

  let $dots = $carousel.find('.flickity-page-dots');
  let $currentDot = $carousel.find('.flickity-page-dots .dot').eq(index);
  if ($currentDot.length > 0) {



    // $dots[0].scrollLeft = $currentDot[0].offsetLeft; // this wil do that immediately
    let from = $dots[0].scrollLeft;
    let to = $currentDot[0].offsetLeft;
    let diff = to - from;
    animate({
      progress: function (percentage) {
        $dots[0].scrollLeft = from + easeInOutQuad(percentage) * diff;
      }
    }, .3);
  }
};

const addClasses = function ($carousel, settings) {
  let map = {
    'has-one-page': hasOnePage($carousel),
    'has-dots': settings.hasDots !== false
  };

  for (let key in map) {
    $carousel.toggleClass(key, map[key]);
  }

  $carousel[0].offsetWidth;
};

// Flickity - fade in for no FOUC, vanilla JS
// https://codepen.io/desandro/pen/JGoGpm
const carouselInit = function (carousel) {
  let $carousel = $(carousel);
  let settings = {
    cellAlign: 'left',
    wrapAround: true
  };
  let options = getOptionsByElement($carousel);
  settings = $.extend(settings, options);

  settings.on = {
    ready: function () {
      // if(settings.asDotTitlesFor) {
      //   $(settings.asDotTitlesFor).each(function(){
      //     // $(this).text();
      //   });
      // }

      wrapDots($carousel, settings);
      addTitlesToDots($carousel, settings);
      addPageNumbers($carousel, settings);
      // rePositionCarousel(carousel);
    },
    select: function (index) {
      let direction = getDirection($carousel, settings, index);
      // console.log('direction', direction);

      // $carousel.addClass('is-animating');
      navigateAsNavFor($carousel, settings, index);
      updatePageNumbers($carousel, settings, index);
      updateDotsScrollPositions($carousel, settings, index, direction);
    },
    settle: function () {
      // $carousel.removeClass('is-animating');
      // $carousel.data('disable-navigate-as-nav-for', false);
    },
    change: function (index) {
      pauseVideos($carousel);
      playVideos($carousel.find('.c-carousel__item:nth-child(' + (index + 1) + ')'));
      $carousel.data('previousIndex', index);
    }
  };

  // $carousel.removeClass('u-hidden');
  addClasses($carousel, settings);
  $carousel[0].offsetHeight;
  let flkty = new Flickity(carousel, settings);

  $carousel.data('previousIndex', flkty.selectedIndex);
};

export default function carousel() {
  // When Carousel exists
  $('[data-ride="carousel"]').each(function () {
    carouselInit(this);
  });
}
