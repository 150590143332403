import $ from 'jquery';
import addRecaptcha from 'Utils/addRecaptcha';

export default function recaptcha(){
  // When Carousel exists
  $('[data-recaptcha]').each(function(){
    let $el = $(this);
    let siteKey = $el.data('recaptcha');
    if(!siteKey) {
      return;
    }
    addRecaptcha(this, {
      'sitekey': siteKey,
      'callback': function(response){
        console.log('callback', response);
      }
    });
  });
}
