// Device State Detection with CSS Media Queries and JavaScript
// @source https://davidwalsh.name/device-state-detection-css-media-queries-javascript

// Create the state-indicator element
export function getIndicator(){
  if(!window.deviceStateIndicator) {
    window.deviceStateIndicator = document.createElement('div');
    deviceStateIndicator.className = 'u-state-indicator';
    document.body.appendChild(window.deviceStateIndicator);
  }
  return window.deviceStateIndicator;
}

// Create a method which returns device state
export default function getDeviceState() {
  return window
    .getComputedStyle(
      getIndicator(),
      ':before'
    )
    .getPropertyValue('content')
    // remove quotes
    .substring(1)
    .substring(-1);
}
