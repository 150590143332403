/**
 * Lightbox
 */

import 'Utils/_events.polyfill';
import 'Utils/_imagesLoaded.polyfill.js';

import $ from 'jquery';
import isUrl from 'Utils/isUrl';
import getYoutubeId from 'Utils/getYoutubeId';
import loadYoutubeAPI from 'Utils/loadYoutubeAPI';
import addYoutubeVideo from 'Utils/addYoutubeVideo';
import animationIterator from 'Utils/animationIterator';
import requestFullScreen from 'Utils/requestFullScreen';

import {whichTransitionEvent} from 'Utils/events';
const transitionEnd = whichTransitionEvent();

import {random} from 'Utils/random';

import template from './lightbox.twig';

let $doc = $(document);
let $html = $('html');
let $body = $('body');
let isRtl = $html.is('.is-rtl') || $body.is('.is-rtl');

let counter = 0;
let baseZIndex = 1000;

let playerTargetHolder = 'lightboxVideos';
window[playerTargetHolder] = {};

let inlineTargetHolder = 'inlineTargets';
window[inlineTargetHolder] = {};

function onLoaded($box) {
  $box.removeClass('is-loading');
  $box.addClass('is-loaded');
}

const onClosed = function (e) {
  // console.log('onClosed::child', e);
  let $box = $(e.target);
  if($box.is('.c-lightbox')) {
    // console.log('onClosed::parent', e);
    let id = $box.attr('id');

    if($box.data('inline') && window[inlineTargetHolder][id]) {
      window[inlineTargetHolder][id].target.appendTo(window[inlineTargetHolder][id].parent);
      delete window[inlineTargetHolder][id];
    }

    $box.off(transitionEnd, onClosed);
    $box.remove();
  }
};

function closeLightbox($box) {
  if($box.data('youtube-id')) {
    let ytId = $box.data('youtube-id');
    if(window[playerTargetHolder][ytId]) {
      animationIterator(function(state){
        let volume = Math.max(0, Math.min(100, 100 - state * 100));
        window[playerTargetHolder][ytId].setVolume(volume);
      });
    }
  }

  let $previous = $box.find('.c-lightbox__action--previous:visible');
  if($previous.length > 0) {
    $box.find('.c-off-canvas').data('off-canvas-level', 0);
    $box.find('.c-off-canvas__body').css('transform', '');
    $box.find('.c-off-canvas__body .o-menu__item.is-active').removeClass('is-active');
  }

  $box.addClass('is-closing');
  $box.on(transitionEnd, onClosed);
  $box.removeClass('is-active');
  $box.removeClass('is-loading');
}

// off-canvas sub-menu
function openSubMenu (e) {
  e.preventDefault();

  let $this = $(this);
  let $parent = $this.closest('.o-menu__item--has-sub');
  let $subMenu = $parent.find('>.o-menu__list--sub');

  $parent.addClass('is-active');
  $subMenu.addClass('o-touch-scroll');
  $parent.closest('.o-touch-scroll').removeClass('o-touch-scroll');

  let $lightbox = $this.closest('.c-lightbox');
  let $canvas = $lightbox.find('.c-off-canvas');
  let $canvasInner = $canvas.find('.c-off-canvas__body');
  let level = $canvas.data('off-canvas-level');
  if (!level) {
    level = 0;
  }

  $canvas.data('off-canvas-level', ++level);
  $canvasInner.css('transform', 'translateX(' + (isRtl ? '' : '-') + level * 100 + '%)');
  $lightbox.find('.c-lightbox__action--previous').toggleClass('is-active', level > 0);
  // scrollTo(0);
}
$doc.on('click', '.o-menu--nested .o-menu__item--has-sub > .o-menu__link', openSubMenu);

function closeSubMenu(e) {
  console.log('closeSubMenu');
  e.preventDefault();
  let $this = $(this);
  let $lightbox = $this.closest('.c-lightbox');
  let $canvas = $lightbox.find('.c-off-canvas');
  let $canvasInner = $canvas.find('.c-off-canvas__body');
  let $parent = $canvas.find('.o-menu__item.o-menu__item--has-sub.is-active').last();
  let $subMenu = $parent.find('>.o-menu__list--sub');

  setTimeout(function () {
    $parent.removeClass('is-active');
    $subMenu.removeClass('o-touch-scroll');

    let $parentSubMenu = $parent.closest('.o-menu__list--sub');
    if ($parentSubMenu.length > 0) {
      $parentSubMenu.addClass('o-touch-scroll');
    } else {
      $parent.closest('.o-menu').addClass('o-touch-scroll');
    }
  }, 350);

  let level = $canvas.data('off-canvas-level');
  if (!level) {
    level = 1;
  }

  $canvas.data('off-canvas-level', --level);
  if (level > 0) {
    $canvasInner.css('transform', 'translateX(' + ( isRtl ? '' : '-' ) + level * 100 + '%)');
  } else {
    $canvasInner.css('transform', '');
  }

  $lightbox.find('.c-lightbox__action--previous').toggleClass('is-active', level > 0);
}
$doc.on('click', '.c-lightbox__action--previous', closeSubMenu);

export default function lightbox() {

  $doc.on('click', '[data-toggle="lightbox"]', function(e){
    e.preventDefault();

    let $this = $(this);

    let target = $this.attr('href') || $this.data('target');
    if (!target) return;

    let $target = [];
    let $targetParent;

    let isFullScreen = $this.data('full-screen');
    let atts = [];
    let classes = $this.data('classes') ? ' ' + $this.data('classes') : '';

    counter++;
    let id = 'lightbox-' + counter;

    let content = ''; // '<img src="https://picsum.photos/id/' + random(1, 999) + '/2000/1000.jpg" alt="">';
    // let close = '&times;';


    let youtubeId = false;
    if (isUrl(target)) {
      youtubeId = getYoutubeId(target);
      if (youtubeId) {
        content = '<div class="o-ratio o-ratio--16:9"><div class="c-lightbox__embed o-ratio__content"></div></div></div>';
        atts.push('data-youtube-id="'+youtubeId+'"')
      } else {
        content = '<img src="'+target+'" alt="">';
      }
    } else {
      $target = $(target);
      $targetParent = $target.parent();
    }

    let html = template
      .replace('{{ id }}', id)
      .replace('{{ zIndex }}', baseZIndex + counter)
      .replace('{{ content }}', content)
      // .replace('{{ close }}', close)
      .replace('{{ atts }}', atts.join( ))
      .replace('{{ classes }}', classes)
    ;
    $(html).appendTo(document.body);
    let $box = $('#' + id);
    $box[0].offsetWidth;

    if($target.length > 0) {
      if(!$target.data('target-parent')) {
        $box.data('inline', true);
        window[inlineTargetHolder][id] = {
          target: $target,
          parent: $targetParent,
        };
      }
    }

    $box.addClass('is-active');
    $box.addClass('is-loading');
    if (youtubeId) {
      $box.addClass('is-iframe');
      loadYoutubeAPI(function () {
        let playerHolder = $box.find('.c-lightbox__embed')[0];
        let playerWrapper = playerHolder.parentNode;
        addYoutubeVideo(
          playerHolder,
          youtubeId,
          function (player, event) {

            onLoaded($box);

            if (isFullScreen) {
              requestFullScreen(playerWrapper, 1000);
            }
          },
          function () {
            closeLightbox($box);
          },
          playerTargetHolder
        );
      });
    } else {
      if($target.length > 0) {
        $target.appendTo( $box.find('.c-lightbox__content') );
        onLoaded($box);
      } else {
        $box[0].imagesLoaded(function () {
          onLoaded($box);
        });
      }
    }
  });


  $doc.on('click', '.c-lightbox', function (e) {
    if($(e.target).is('.c-lightbox')) {
      closeLightbox($(this));
    }
  });


  $doc.on('click', '.c-lightbox__action--close', function () {
    closeLightbox($(this).closest('.c-lightbox'));
  });

}
