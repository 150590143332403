/**
 * Responsive Video
 */

import $ from 'jquery';

import { hasObjectFit, isMobileWidth } from 'Utils/detectFeatures';

import template from './video-template.twig'

const available_types = {
  'mp4': 'video/mp4',
  'ogg': 'video/ogg'
};

export default function responsiveVideo() {

  // When Carousel exists
  if (document.querySelectorAll('[data-ride="responsive-video"]')) {

    const init = function (el) {

      let $this = $(el);

      if($this.data('object-fit') && !hasObjectFit()) {
        return;
      }

      if($this.data('only-desktop') && isMobileWidth()) {
        return;
      }

      let sources = [];

      for (let type in available_types) {
        if ($this.data(type)) {
          sources.push('<source src="' + $this.data(type) + '" type="' + available_types[type] + '"/>');
        }
      }

      let div = document.createElement('div');
      div.setAttribute('class', 'c-responsive-video__holder');
      div.innerHTML = template
        .replace('{{ sources }}', sources.join(''))
      ;
      el.appendChild(div);
    };

    $('[data-ride="responsive-video"]').each(function () {
      init(this);
    });
  }

}
